import React from "react";
import Vimeo from '@u-wave/react-vimeo';
import vimeoList from "../../static/videos/vimeo";
import youTubeList from "../../static/videos/youtube";
import styled from "styled-components";

const VideoContainer = styled.div`
  .video-iframe{
    width:100%;
  }
  iframe{
    width:100%;
  }
  @media(max-width:550px){
    .video-iframe{
      max-width: 550px;
      overflow:auto;
    }
  }
`;

const SocialMediaVideos = () => {
  return (
    <VideoContainer className="px-4">
      {
        vimeoList.videos.map((video, index) => {
          return (<div key={video.id} className="col-12 py-3 text-center justify-content-center">
            <Vimeo video={video.id} width="640" height="360" className="video-iframe" />
          </div>)
        })
      }
      {
        youTubeList.videos.map(video => {
          return (<div key={video.id} className="col-12 py-3 text-center justify-content-center">
            <iframe
              src={`https://www.youtube.com/embed/${ video.id }`}
              frameBorder="0"
              className="video-iframe"
              width="640" height="360"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />

          </div>)
        })
      }
    </VideoContainer>
  );
}

export default SocialMediaVideos;