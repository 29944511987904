import "react-toastify/dist/ReactToastify.css";

import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import Layout from "../components/layout";
import { Link } from "gatsby";
import News from "../components/news";
import ReactGA from "react-ga";
import SEO from "../components/seo";
import SocialMediaVideos from "../components/socialMediaVideos";
import { graphql } from "gatsby";
import styled from "styled-components";
import { toastSettings } from "../utils/constants";

ReactGA.initialize(process.env.GOOGLE_ID);

const MainContainer = styled.div`
    .overlay {
        width: 50%;

        padding: 75px 95px 75px 70px;

        opacity: 0.9;

        color: white;

        @media (max-width: 765px) {
            width: 100%;

            padding: 10px;
        }
    }
`;

function MainContent(props) {
    return (
        <div className={props.className}>
            <h3>Welcome</h3>
            <h5 className="my-5">The New Season Stickers Are Here!</h5>
      Head on over to the <Link to="/store/">Store</Link> and grab
      yourself a few!
            <SocialMediaVideos />
        </div>
    );
}

export default function IndexPage(props) {
    const news = props.data.news;

    const opts = {
        width: "640",

        height: "360",

        playerVars: {
            autoplay: 0,

            host: "https://www.youtube.com",

            origin: "https://www.jackalopeadventures.com",
        },
    };

    useEffect(() => {
        if (typeof "window" !== "undefined") {
            ReactGA.pageview(window.location.pathname + window.location.search);

            opts.playerVars.host = window.location.host;
        }

        const cartMessage = props.location.search;

        if (cartMessage === "?cancel") {
            toast.info("Your order has been cancelled.", toastSettings);
        }

        if (cartMessage === "?success") {
            toast.success("🦄 Your order is complete.", toastSettings);
        }
    }, []);

    const siteTitle = "Jackalope Adventures - Home Page";

    return (
        <Layout location={props.location} title={siteTitle}>
            <SEO
                title="Home"
                keywords={[
                    "mountain bike lessons",
                    "enduro mountain bike lessons",
                    "bike lessons utah",
                    "jackalope adventures",
                    "jackalope",
                    "backcountry skiing",
                    "utah ski touring",
                ]}
            />

            <MainContainer className="row position-relative">
                <News className="col-12 col-lg-6 p-3" news={news} />

                <MainContent className="col-12 col-lg-6 p-3" opts={opts} />
            </MainContainer>

            <ToastContainer />
        </Layout>
    );
}

export const pageQuery = graphql`
    query {
        news: allMdx(
            filter: { frontmatter: { isNews: { eq: true } } }

            sort: { order: DESC, fields: frontmatter___date }
        ) {
            nodes {
                frontmatter {
                    isNews

                    date(formatString: "MMM D, YYYY")

                    description

                    author

                    path

                    title
                }

                body
            }
        }
    }
`;
