import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";
import styled from "styled-components";
import ReactYouTube from "./reactYouTube";
import ReactVimeo from "./reactVimeo";
import { MDXProvider } from "@mdx-js/react";

const NewsItem = (props) => {
    const Shortcodes = { ReactYouTube, ReactVimeo };
    return (
        <>
            <h5>{props.news.frontmatter.title}</h5>
            <h6 className="py-0 my-0">Author: {props.news.frontmatter.author ? props.news.frontmatter.author : "Corey"}</h6>

            <small>{props.news.frontmatter.date}</small>
            <div className="border-top mb-5 p-3">
                <MDXProvider components={{ ...Shortcodes }}>
                    <MDXRenderer>{props.news.body}</MDXRenderer>
                </MDXProvider>
            </div>
        </>
    )
}

const NewsContainer = styled.div`
    h3 {
        margin-bottom: 20px;
    }
    small{
        font-size:12px;
        margin-bottom:20px;
        display:inline-block;
    }
`;

const News = (props) => {
    return (
        <NewsContainer className={props.className} suppressHydrationWarning>
            <h3>News</h3>
            {props.news.nodes.map((news, index) => {
                return <NewsItem news={news} key={index} className="mt-5" />
            })}
        </NewsContainer>
    )
}

export default News;